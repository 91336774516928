import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteInfo } from '../../layouts/_Models/sidebar.metadata';
import { VerticalSidebarService } from '../../layouts/vertical-sidebar/vertical-sidebar.service';
import { Router } from '@angular/router';
import { Permission } from "../../Login/_guards/Permission";

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
 // styleUrls: ['./card-menu.component.scss']
})
export class CardMenuComponent {
  public sidebarnavItems: RouteInfo[] = [];
  public expandedItems: Set<string> = new Set();

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor(private menuService: VerticalSidebarService, private router: Router, private permission: Permission) {
    this.menuService?.items?.subscribe(menuItems => {
      this.sidebarnavItems = this.getVisibleItem(menuItems);
    });
  }

  getVisibleItem(elements: RouteInfo[]): RouteInfo[] { //TODO: Espandere
    return elements?.filter(x =>
      (x?.enabled == undefined || x?.enabled == true) &&
      this.permission.HasCapacity(x?.capacita) &&
      this.permission.isOperativeRoles(x?.ruoliOperativi) &&
      this.permission.isntOperativeRoles(x?.notruoliOperativi)
    ); //Test
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

  toggleExpand(itemTitle: string) {
    if (this.expandedItems.has(itemTitle)) {
      this.expandedItems.delete(itemTitle);
    } else {
      this.expandedItems.add(itemTitle);
    }
  }

  hasSubmenu(item: RouteInfo): boolean {
    return item.submenu && item.submenu.length > 0;
  }
}