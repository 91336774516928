<div class="dashboard-container">
    <div class="dashboard-grid">
      <div class="dashboard-item" *ngFor="let sidebarnavItem of sidebarnavItems" (click)="hasSubmenu(sidebarnavItem) ? toggleExpand(sidebarnavItem.title) : navigateTo(getPath(sidebarnavItem.path))">
        <i [ngClass]="[sidebarnavItem.icon ?? '']"></i>
        <span>{{ sidebarnavItem.title | translate }}</span>
        <i *ngIf="hasSubmenu(sidebarnavItem)" [ngClass]="{'expanded': expandedItems.has(sidebarnavItem.title), 'collapsed': !expandedItems.has(sidebarnavItem.title)}"></i>
        <div *ngIf="hasSubmenu(sidebarnavItem) && expandedItems.has(sidebarnavItem.title)" class="submenu">
          <div class="submenu-item" *ngFor="let subItem of sidebarnavItem.submenu"
            (click)="hasSubmenu(subItem) ? toggleExpand(subItem.title) : navigateTo(getPath(subItem.path))">
            <i [ngClass]="[subItem.icon ?? '']"></i>
            <span>{{ subItem.title | translate }}</span>
            <i *ngIf="hasSubmenu(subItem)"
              [ngClass]="{'expanded': expandedItems.has(subItem.title), 'collapsed': !expandedItems.has(subItem.title)}"></i>
          </div>
        
          <div *ngIf="hasSubmenu(subItem) && expandedItems.has(subItem.title)" class="submenu">
            <div class="submenu-item" *ngFor="let subSubItem of subItem.submenu" (click)="navigateTo(getPath(subSubItem.path))">
              <i [ngClass]="[subSubItem.icon ?? '']"></i>
              <span>{{ subSubItem.title | translate }}</span>
            </div>
          </div>
        </div>
        </div>
      </div>
  </div>
  