/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  .  .  .  . .  .  . .  .
    *    .       .       .       .       .       .       .       .       .       .       .       .
    *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
    *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
    *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
    *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
    *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
    *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
    *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
    *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
    *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
    *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
    *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
    *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
    *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
    *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *
 * Copyright FortyFix(c) 2024.
 * Title: Fasi.ts;
 * Path: ./src/environments/Modules/Fasi.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 12/03/25, 13:00;
 * Updated at: 12/03/25, 13:00;
 *
 */

import { OggettoConfig } from "src/app/shared/_services/dynamic-crud.service";
import { PrimoDelMeseString, UltimoDelMeseString } from "../Utility/DateUtility";



function Ritiro() {
    var OBJ: OggettoConfig = {
        HasAddList: {},//da usare come hideif
        Capacita: ['gestione-ritiri'],
        Id: "ritiri",
        listTitle: "ritiri",
        RestRoute: '/progetti/ritiri',
        HasGet:false,
        ListResponseHasData: true,
        GetResponseHasData: true,
        ListDefaultFilter: { inizio: PrimoDelMeseString(), fine: UltimoDelMeseString(), periodo: true, },
        OnSaveMap: (x) => {
            if (!x.id) {
                x.progetti = [{}, ...(x.progetti ?? [])];
            }
            return x
        },
        MenuItems: [
            {
                path: "/shared/dynamiclist/ritiri",
                title: "Ritiri",
                icon: "fas fa-pickup",
                class: "",
                capacita: ['menu-ritiri'],
            },
        ],
        Azioni: [
            {
                nome: 'Elimina',
                close: true,
                class: ' btn ',
                labelClass: 'ml-1 text-danger font-weight-bold',
                type: 'POST',
                restRoute: '/progetti/ritiri/delete',
                preAlert: 'Sei sicuro di voler eliminare questo elemento?',
                capacita: ['gestione-fabbisogno'],
                icon: 'fas fa-trash text-danger',
                hideif: { fieldnotexist: 'id' },
                placeholder: 'Elimina',
                successMex: 'Elemento eliminato con successo',
                parameter: [{ nome: "id", parameter: 'id' }]
            }
        ],
        Headers: [
            {
                nome: 'Nominativo',
                oggetti: ['cliente'],
                inputType: 'text',
            },
            { nome: "Inizio", oggetti: ['data_inizio_prevista'], inputType: 'datetime-local', },
            { nome: "Fine", oggetti: ['data_fine_prevista'], inputType: 'datetime-local', },
            { nome: 'Ordini', oggetti: ['ordine'], type: 'Array', class: 'col-lg-4',},
        ],
        Filters: [
            { nome: 'Inizio:', oggetti: ['inizio'], modificabile: true, inputType: 'date', class: 'col-lg-4'  },
            { nome: 'fine:', oggetti: ['fine'], modificabile: true, inputType: 'date', class: 'col-lg-4', },
            {
                nome: 'Periodo:', oggetti: ['periodo'], modificabile: true, inputType: 'checkbox', class: 'col-lg-2'
            },
            { nome: 'ordine:', oggetti: ['ordine'], modificabile: true, inputType: 'text', class: 'col-lg-4', },
            { nome: 'cliente:', oggetti: ['cliente'], modificabile: true, inputType: 'text', class: 'col-lg-4',},
            {
                nome: 'Stato',
                source: '/progetti/ritiri/stati',
                oggetti: ['stato'],
                internalOutput: 'statoObject',
                OutputField: 'stato',
                modificabile: true,
                required: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    selectFirst: true,
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
           
        ],
        campi: [
            {
                nome: 'Nominativo',
                oggetti: ['cliente'],
                modificabile: true,
                inputType: 'text',
                disabledif: {
                    func: (x) => {
                        return true;
                    }
                },
                required: true,
                class: "col-lg-6",
            },
            {
                nome: 'E-Mail',
                oggetti: ['email'],
                modificabile: true,
                inputType: 'text',
                disabledif: {
                    func: (x) => {
                        return true;
                    }
                },
                required: true,
                class: "col-lg-6",
            },
            // { nome: "Ordini", oggetti: ['ordine'], type: 'objArray', },
            { nome: "Inizio", oggetti: ['data_inizio_prevista'], inputType: 'datetime-local', modificabile: true },
            { nome: "Fine", oggetti: ['data_fine_prevista'], inputType: 'datetime-local', modificabile: true },
            { nome: "Note", oggetti: ['note'], inputType: 'textarea', modificabile: true },
            {
                nome: 'Stato',
                source: '/progetti/ritiri/stati',
                oggetti: ['stato'],
                internalOutput: 'statoObject',
                OutputField: 'stato',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
            { nome: 'Ordini:', oggetti: ['ordine'], modificabile:true, type: 'Array', class: 'col-lg-4', },
        ]
    }
    return OBJ;
};




export var Ritiri = () => {

    var env = {
        Cruds: [Ritiro()],
        Menu: [...Ritiro().MenuItems],
        Modules: {
            "Ritiri": true
        }
    };
    return env;
}