import { Component, Input, OnInit } from "@angular/core";
import { DynamicCrudService, OggettoConfig } from "../_services/dynamic-crud.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CampoAggiuntivo, CampoUtility } from "../models/campo-aggiuntivo";
import { Permission } from "src/app/Login/_guards/Permission";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DynamicEditComponent } from "../dynamic-edit/dynamic-edit.component";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html'
})
export class DynamicListComponent implements OnInit {
  CrudSettings: OggettoConfig = undefined;
  @Input() CrudId: string;
  environment = environment;
  @Input() openFilter: boolean = false;
  List: any[] = [];
  OriginalList: any[] = [];
  private ordine: { field: string, order: 'asc' | 'desc' } = { field: '', order: 'asc' };
  @Input() title: string = 'List';
  @Input() FilterViewType: 'normal' | 'sidebar' = 'normal';
  @Input() filtro: any = {}
  @Input() RowClickEnabled: boolean = true;
  @Input() ConcatParams: any = {};
  constructor(
    private crudService: DynamicCrudService,
    private activeRoute: ActivatedRoute,
    public campoUtility: CampoUtility,
    private permission: Permission,
    private modalService: NgbModal,
    private titleService: Title,
    public translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.print();
    if (!this.CrudId)
      this.activeRoute.params.subscribe(
        (params: { type: string }) => {
          this.CrudSettings = this.crudService.GetCrudSettings(params.type);
          this.filtro = {};
          this.Refresh();
        }
      );
    else {
      this.CrudSettings = this.crudService.GetCrudSettings(this.CrudId);
      this.Refresh();
    }
  }
  Refresh() {
    const filter = { page: 0, size: 10, ...DeepCopy(this.CrudSettings.ListDefaultFilter) };
    this.filtro = Object.assign({}, filter, this.filtro);
    if (!this.CrudId)
      this.updateTitle();
    this.crudService.GetList(Object.assign({}, this.CrudSettings), this.filtro).subscribe(response => {
      this.List = this.CrudSettings.ListResponseHasData ? response.data : response;
      this.OriginalList = Object.assign([], this.List);
    })
  }
  hasListActions() {
    return this.CrudSettings && this.CrudSettings['AzioniLista']?.filter(x => !x.single)?.length > 0;
  }
  MassiveButtonClick(button) {
    this.crudService.MultipleCustomButtonClick(this.List.filter(x => x['selected']), button, true, this.CrudSettings, (end) => this.Refresh(), (refresh) => { this.Refresh() }, (close) => { })
  }
  ButtonClick(item, button) {
    this.crudService.CustomButtonClick(item, button, true, this.CrudSettings, (end) => this.Refresh(), (refresh) => { this.Refresh() }, (close) => { })
  }
  dynamicHeader() {
    return this.CrudSettings?.Headers;
  }
  IsArray(val) {
    return val && Array.isArray(val);
  }
  headShow(campo): boolean {
    return true;
  }
  allSelected(headerField: any, data: any[]) {
    return data?.every(x => x[headerField]);
  }

  toggleAll(headerField: any, data: any[]) {
    var oldValue = Object.assign({}, { val: this.allSelected(headerField, data) })
    data?.forEach((x) => x[headerField] = !oldValue.val)
  }
  RowClick(Item) {
    if (!this.RowClickEnabled || this.dynamicHeader()?.some(x => x.modificabile)) return;
    this.OpenEdit(Item);
  }
  EditButton(Item) {
    this.OpenEdit(Item);
  }
  HasAddButton() {
    return this.CrudSettings?.HasAddList &&
      this.campoUtility.CheckValue(this.CrudSettings?.HasAddList, Object.assign({}, this.CrudSettings.ListDefaultFilter, this.filtro)) &&
      this.permission.HasCapacity(this.CrudSettings.Capacita);
  }
  AddClick() {
    this.OpenEdit()
  }
  OpenEdit(Item?: Object) {
    if (!this.CrudSettings.EditView)
      this.OpenEditModal(Item)
    else
      switch (this.CrudSettings.EditView) {
        case 'Modal':
          this.OpenEditModal(Item);
          break;
        case 'Page':
          this.OpenEditPage(Item);
          break;
      }
  }
  OpenEditModal(Item?: Object) {
    const EditModal = this.modalService.open(DynamicEditComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl'
    });
    (<DynamicEditComponent>EditModal.componentInstance).CrudSettings = this.CrudSettings;
    (<DynamicEditComponent>EditModal.componentInstance).Item = Item ?? Object.assign(this.CrudSettings.AddDefaultObject ?? {}, this.ConcatParams);
    (<DynamicEditComponent>EditModal.componentInstance).updated.subscribe(data => {
      setTimeout(() => { this.Refresh(); }, 1000)
    });
  }
  OpenEditPage(Item?: any) {
    var params = [this.CrudSettings.Id, Item?.id];
    var link = `shared/dynamicedit/${params?.filter(x => x).join('/')}`;
    this.router.navigate([this.activeRoute.snapshot.queryParams[link] || link,]);
  }
  ExportCsv() {
    this.crudService.DownloadCsv(this.List, this.CrudSettings, `${this.CrudSettings.listTitle} Export`, {});
  }


  order(campo: CampoAggiuntivo) {
    var order: 'asc' | 'desc' = campo.nome == this.ordine.field ? (this.ordine.order == 'asc' ? 'desc' : 'asc') : 'asc'
    this.List = this.campoUtility.getSortedList(this.OriginalList, campo, order);
    this.ordine = { field: campo.nome, order: order }
  }

  private updateTitle() {
    this.title = this.CrudSettings.listTitle ?? 'Lista'
    this.titleService.setTitle(this.translate.instant(this.title));
  }
  Tooltip(item) {
    return ''
  }

  Stampe(item) {
    return item?.stampe?.filter(stampa => (!item?.template && item.tipo != "F") || stampa.id == item.template);
  }

  print() {
    var list = [
      {
        "product": "Frigo Vetrina, cl-372-v-white",
        "link": "/settori/beverage/frigo-vetrina/cl-372-v-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-420-l-black",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-420-l-black/"
      },
      {
        "product": "Isole, igloo-18",
        "link": "/settori/mini-market-super-market/isole/igloo-18/"
      },
      {
        "product": "Frigo Vetrina, cl-372-v-black",
        "link": "/settori/beverage/frigo-vetrina/cl-372-v-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-420-l-natural",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-420-l-natural/"
      },
      {
        "product": "Isole, igloo-21",
        "link": "/settori/mini-market-super-market/isole/igloo-21/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vg-white",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vg-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-420-l-noce-medio",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-420-l-noce-medio/"
      },
      {
        "product": "Isole, igloo-25",
        "link": "/settori/mini-market-super-market/isole/igloo-25/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vg-black",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vg-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-420-l-white",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-420-l-white/"
      },
      {
        "product": "Isole, capri-180",
        "link": "/settori/mini-market-super-market/isole/capri-180/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vs",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vs/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-820-l-black",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-820-l-black/"
      },
      {
        "product": "Isole, capri-210",
        "link": "/settori/mini-market-super-market/isole/capri-210/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vgc",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vgc/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-820-l-natural",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-820-l-natural/"
      },
      {
        "product": "Isole, manhattan-14",
        "link": "/settori/mini-market-super-market/isole/manhattan-14/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-820-l-noce-medio",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-820-l-noce-medio/"
      },
      {
        "product": "Isole, manhattan-18",
        "link": "/settori/mini-market-super-market/isole/manhattan-18/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vgc-black-d-class",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vgc-black-d-class/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-820-l-white",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-820-l-white/"
      },
      {
        "product": "Isole, manhattan-21",
        "link": "/settori/mini-market-super-market/isole/manhattan-21/"
      },
      {
        "product": "Frigo Vetrina, cl-372-vgc-black",
        "link": "/settori/beverage/frigo-vetrina/cl-372-vgc-black/"
      },
      {
        "product": "Frigo Vetrina, thin-cooler-white",
        "link": "/settori/beverage/frigo-vetrina/thin-cooler-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-372-vg",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-372-vg/"
      },
      {
        "product": "Isole, manhattan-25",
        "link": "/settori/mini-market-super-market/isole/manhattan-25/"
      },
      {
        "product": "Frigo Vetrina, thin-cooler-black",
        "link": "/settori/beverage/frigo-vetrina/thin-cooler-black/"
      },
      {
        "product": "Isole, manhattan-btm-145",
        "link": "/settori/mini-market-super-market/isole/manhattan-btm-145/"
      },
      {
        "product": "Frigo Vini Libera Installazione, icool-401-w",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/icool-401-w/"
      },
      {
        "product": "Frigo Vetrina, slim-cooler-225-t-white",
        "link": "/settori/beverage/frigo-vetrina/slim-cooler-225-t-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, icool-40-w",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/icool-40-w/"
      },
      {
        "product": "Frigo Vetrina, slim-cooler-225-t-black",
        "link": "/settori/beverage/frigo-vetrina/slim-cooler-225-t-black/"
      },
      {
        "product": "Isole, manhattan-btm-210",
        "link": "/settori/mini-market-super-market/isole/manhattan-btm-210/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-300-xh",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-300-xh/"
      },
      {
        "product": "Frigo Vini Libera Installazione, clw-500-xh",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/clw-500-xh/"
      },
      {
        "product": "Frigo Vetrina, icool-401-wht",
        "link": "/settori/beverage/frigo-vetrina/icool-401-wht/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-20-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-20-g1tb/"
      },
      {
        "product": "Frigo Vetrina, icool-401-blk",
        "link": "/settori/beverage/frigo-vetrina/icool-401-blk/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-37-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-37-g1tb/"
      },
      {
        "product": "Frigo Vetrina, icool-401-blk-surgiva",
        "link": "/settori/beverage/frigo-vetrina/icool-401-blk-surgiva/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-37-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-37-g2tb/"
      },
      {
        "product": "Frigo Vetrina, icool-401-g-sz-wht",
        "link": "/settori/beverage/frigo-vetrina/icool-401-g-sz-wht/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-46-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-46-g2tb/"
      },
      {
        "product": "Frigo Vetrina, icool-401-g-sz-blk",
        "link": "/settori/beverage/frigo-vetrina/icool-401-g-sz-blk/"
      },
      {
        "product": "Frigo Vetrina, icool-40-g-white",
        "link": "/settori/beverage/frigo-vetrina/icool-40-g-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-51-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-51-g1tb/"
      },
      {
        "product": "Frigo Vetrina, icool-40-g-black",
        "link": "/settori/beverage/frigo-vetrina/icool-40-g-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, dw-82-inox",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/dw-82-inox/"
      },
      {
        "product": "Vetrine espositive calde e fredde, psg-67-nfp",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/psg-67-nfp/"
      },
      {
        "product": "Frigo Vini Libera Installazione, vinarius-40-blk",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/vinarius-40-blk/"
      },
      {
        "product": "Frigo Vetrina, nora-200",
        "link": "/settori/beverage/frigo-vetrina/nora-200/"
      },
      {
        "product": "Frigo Vini Libera Installazione, vinarius-40-grn",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/vinarius-40-grn/"
      },
      {
        "product": "Frigo Vetrina, nora-240",
        "link": "/settori/beverage/frigo-vetrina/nora-240/"
      },
      {
        "product": "Frigo Vini Libera Installazione, vinarius-40-gry",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/vinarius-40-gry/"
      },
      {
        "product": "Frigo Vetrina, nora-280",
        "link": "/settori/beverage/frigo-vetrina/nora-280/"
      },
      {
        "product": "Vetrine espositive calde e fredde, psg-90-nfp",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/psg-90-nfp/"
      },
      {
        "product": "Frigo Vini Libera Installazione, vinarius-40-orn",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/vinarius-40-orn/"
      },
      {
        "product": "Frigo Vetrina, gn-400-gtn",
        "link": "/settori/beverage/frigo-vetrina/gn-400-gtn/"
      },
      {
        "product": "Frigo Vini Libera Installazione, vinarius-40-wht",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/vinarius-40-wht/"
      },
      {
        "product": "Frigo Vetrina, gn-400-gtn-blk",
        "link": "/settori/beverage/frigo-vetrina/gn-400-gtn-blk/"
      },
      {
        "product": "Frigo Vetrina, cl-801-v2gc-sl-white",
        "link": "/settori/beverage/frigo-vetrina/cl-801-v2gc-sl-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-120-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-120-g1tb/"
      },
      {
        "product": "Frigo Vetrina, cl-801-v2gc-sl-black",
        "link": "/settori/beverage/frigo-vetrina/cl-801-v2gc-sl-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-120-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-120-g2tb/"
      },
      {
        "product": "Frigo Vetrina, cl-1100-v2gc-sl-white",
        "link": "/settori/beverage/frigo-vetrina/cl-1100-v2gc-sl-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-160-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-160-g1tb/"
      },
      {
        "product": "Vetrine espositive calde e fredde, psg-76-nfp",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/psg-76-nfp/"
      },
      {
        "product": "Frigo Vetrina, cl-1100-v2gc-sl-black",
        "link": "/settori/beverage/frigo-vetrina/cl-1100-v2gc-sl-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-160-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-160-g2tb/"
      },
      {
        "product": "Frigo Vetrina, cl-1300-v2gc-sl-white",
        "link": "/settori/beverage/frigo-vetrina/cl-1300-v2gc-sl-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-180-g1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-180-g1tb/"
      },
      {
        "product": "Frigo Vetrina, cl-1300-v2gc-sl-black",
        "link": "/settori/beverage/frigo-vetrina/cl-1300-v2gc-sl-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-180-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-180-g2tb/"
      },
      {
        "product": "Frigo Vetrina, CL 90 TC SL WHITE",
        "link": "/settori/beverage/frigo-vetrina/CL 90 TC SL WHITE/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cv180bev",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cv180bev/"
      },
      {
        "product": "Frigo Vetrina, cl-90-tc-sl-black",
        "link": "/settori/beverage/frigo-vetrina/cl-90-tc-sl-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, dw-170-1t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/dw-170-1t/"
      },
      {
        "product": "Frigo Vetrina, cl-113-tc-sl-white",
        "link": "/settori/beverage/frigo-vetrina/cl-113-tc-sl-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, dw-170-2t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/dw-170-2t/"
      },
      {
        "product": "Frigo Vetrina, cl-113-tc-sl-black",
        "link": "/settori/beverage/frigo-vetrina/cl-113-tc-sl-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, dw-190-black",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/dw-190-black/"
      },
      {
        "product": "Frigo Vetrina, icool-80-jumbo-white",
        "link": "/settori/beverage/frigo-vetrina/icool-80-jumbo-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, dw-190-silver",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/dw-190-silver/"
      },
      {
        "product": "Frigo Vetrina, icool-80-jumbo-black",
        "link": "/settori/beverage/frigo-vetrina/icool-80-jumbo-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, slim-wine-112-1tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/slim-wine-112-1tb/"
      },
      {
        "product": "Frigo Vetrina, icool-110-jumbo-white",
        "link": "/settori/beverage/frigo-vetrina/icool-110-jumbo-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, slim-wine-105-2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/slim-wine-105-2tb/"
      },
      {
        "product": "Frigo Vetrina, icool-110-jumbo-black",
        "link": "/settori/beverage/frigo-vetrina/icool-110-jumbo-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-355-g2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-355-g2tb/"
      },
      {
        "product": "Frigo Vetrina, icool-88-t-white",
        "link": "/settori/beverage/frigo-vetrina/icool-88-t-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-355-g3tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-355-g3tb/"
      },
      {
        "product": "Frigo Vetrina, icool-88-t-black",
        "link": "/settori/beverage/frigo-vetrina/icool-88-t-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, cw-355-g4tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/cw-355-g4tb/"
      },
      {
        "product": "Frigo Vetrina, icool-107-t-white",
        "link": "/settori/beverage/frigo-vetrina/icool-107-t-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, sw-224-2tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/sw-224-2tb/"
      },
      {
        "product": "Frigo Vetrina, icool-107-t-black",
        "link": "/settori/beverage/frigo-vetrina/icool-107-t-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, sw-224-3tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/sw-224-3tb/"
      },
      {
        "product": "Frigo Vetrina, icool-40-white",
        "link": "/settori/beverage/frigo-vetrina/icool-40-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, sw-224-4tb",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/sw-224-4tb/"
      },
      {
        "product": "Frigo Vetrina, icool-40-black",
        "link": "/settori/beverage/frigo-vetrina/icool-40-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, norcia-290-3t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/norcia-290-3t/"
      },
      {
        "product": "Frigo Vetrina, icool-40-g-white-sz",
        "link": "/settori/beverage/frigo-vetrina/icool-40-g-white-sz/"
      },
      {
        "product": "Frigo Vini Libera Installazione, norcia-290-4t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/norcia-290-4t/"
      },
      {
        "product": "Frigo Vetrina, icool-40-g-black-sz",
        "link": "/settori/beverage/frigo-vetrina/icool-40-g-black-sz/"
      },
      {
        "product": "Frigo Vini Libera Installazione, norcia-300-v",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/norcia-300-v/"
      },
      {
        "product": "Frigo Vetrina, icool-40-c-white",
        "link": "/settori/beverage/frigo-vetrina/icool-40-c-white/"
      },
      {
        "product": "Frigo Vini Libera Installazione, norcia-360-3t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/norcia-360-3t/"
      },
      {
        "product": "Frigo Vetrina, icool-40-c-black",
        "link": "/settori/beverage/frigo-vetrina/icool-40-c-black/"
      },
      {
        "product": "Frigo Vini Libera Installazione, norcia-360-4t",
        "link": "/settori/frigo-vini/frigo-vini-libera-installazione/norcia-360-4t/"
      },
      {
        "product": "Frigo Vetrina, cv-180-bev",
        "link": "/settori/beverage/frigo-vetrina/cv-180-bev/"
      },
      {
        "product": "Frigo Vetrina, illumia-630-blk",
        "link": "/settori/beverage/frigo-vetrina/illumia-630-blk/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-92-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-92-psv/"
      },
      {
        "product": "Frigo Vetrina, illumia-1270-blk",
        "link": "/settori/beverage/frigo-vetrina/illumia-1270-blk/"
      },
      {
        "product": "Frigo Vetrina, illumia-1620-blk",
        "link": "/settori/beverage/frigo-vetrina/illumia-1620-blk/"
      },
      {
        "product": "Frigo Vetrina, ekg-270-c",
        "link": "/settori/beverage/frigo-vetrina/ekg-270-c/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-93-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-93-psv/"
      },
      {
        "product": "Frigo Vetrina, ekg-380-fl-blk",
        "link": "/settori/beverage/frigo-vetrina/ekg-380-fl-blk/"
      },
      {
        "product": "Frigo Vetrina, ekg-390-c",
        "link": "/settori/beverage/frigo-vetrina/ekg-390-c/"
      },
      {
        "product": "Frigo Vetrina, ekg-390-c-blk",
        "link": "/settori/beverage/frigo-vetrina/ekg-390-c-blk/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-122-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-122-psv/"
      },
      {
        "product": "Frigo Vetrina, ekg-390-v",
        "link": "/settori/beverage/frigo-vetrina/ekg-390-v/"
      },
      {
        "product": "Frigo Vetrina, ekg-390-vg",
        "link": "/settori/beverage/frigo-vetrina/ekg-390-vg/"
      },
      {
        "product": "Frigo Vetrina, ekg-390-vg-blk",
        "link": "/settori/beverage/frigo-vetrina/ekg-390-vg-blk/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-123-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-123-psv/"
      },
      {
        "product": "Frigo Vetrina, ekg-450-c",
        "link": "/settori/beverage/frigo-vetrina/ekg-450-c/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-152-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-152-psv/"
      },
      {
        "product": "Vetrine espositive calde e fredde, deluxe-153-psv",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/deluxe-153-psv/"
      },
      {
        "product": "Vetrine espositive calde e fredde, dlx-70",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/dlx-70/"
      },
      {
        "product": "Vetrine espositive calde e fredde, dlx-90",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/dlx-90/"
      },
      {
        "product": "Vetrine espositive calde e fredde, dlh-70",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/dlh-70/"
      },
      {
        "product": "Vetrine espositive calde e fredde, dlh-90",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/dlh-90/"
      },
      {
        "product": "Vetrine espositive calde e fredde, dlh-120",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/dlh-120/"
      },
      {
        "product": "Vetrine espositive calde e fredde, food-60",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/food-60/"
      },
      {
        "product": "Vetrine espositive calde e fredde, food-80",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/food-80/"
      },
      {
        "product": "Vetrine espositive calde e fredde, crh-551",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/crh-551/"
      },
      {
        "product": "Vetrine espositive calde e fredde, crh-552",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/crh-552/"
      },
      {
        "product": "Vetrine espositive calde e fredde, crh-702",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/crh-702/"
      },
      {
        "product": "Vetrine espositive calde e fredde, crh-703",
        "link": "/settori/pasticceria-gelateria/vetrine-pasticceria/crh-703/"
      },
      {
        "product": "Sotto Banco, icool-30-g-white",
        "link": "/settori/beverage/sotto-banco/icool-30-g-white/"
      },
      {
        "product": "Vetrine neutre, crn-10",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/crn-10/"
      },
      {
        "product": "Abbattitori, abt-03-top",
        "link": "/settori/arredo-cucine/abbattitori/abt-03-top/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-20-f1t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-20-f1t/"
      },
      {
        "product": "Sotto Banco, icool-30-g-black",
        "link": "/settori/beverage/sotto-banco/icool-30-g-black/"
      },
      {
        "product": "Abbattitori, abt-05",
        "link": "/settori/arredo-cucine/abbattitori/abt-05/"
      },
      {
        "product": "Vetrine neutre, crn-20",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/crn-20/"
      },
      {
        "product": "Abbattitori, abt-10",
        "link": "/settori/arredo-cucine/abbattitori/abt-10/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-46-f2t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-46-f2t/"
      },
      {
        "product": "Sotto Banco, s-85-xh",
        "link": "/settori/beverage/sotto-banco/s-85-xh/"
      },
      {
        "product": "Vetrine neutre, crn-30",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/crn-30/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-51-f1t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-51-f1t/"
      },
      {
        "product": "Sotto Banco, s-185-xh",
        "link": "/settori/beverage/sotto-banco/s-185-xh/"
      },
      {
        "product": "Vetrine neutre, crb-20",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/crb-20/"
      },
      {
        "product": "Vetrine neutre, brd-70",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/brd-70/"
      },
      {
        "product": "Vetrine neutre, brd-100",
        "link": "/settori/pasticceria-gelateria/vetrine-neutre/brd-100/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-20-g1t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-20-g1t/"
      },
      {
        "product": "Sotto Banco, s-285-xh",
        "link": "/settori/beverage/sotto-banco/s-285-xh/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-46-g2t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-46-g2t/"
      },
      {
        "product": "Sotto Banco, s-98-xh",
        "link": "/settori/beverage/sotto-banco/s-98-xh/"
      },
      {
        "product": "Frigo Vini da Incasso, dw-51-g1t",
        "link": "/settori/frigo-vini/frigo-vini-da-incasso/dw-51-g1t/"
      },
      {
        "product": "Sotto Banco, s-198-xh",
        "link": "/settori/beverage/sotto-banco/s-198-xh/"
      },
      {
        "product": "Sotto Banco, s-298-xh",
        "link": "/settori/beverage/sotto-banco/s-298-xh/"
      },
      {
        "product": "Sotto Banco, cold-bar-200-blk",
        "link": "/settori/beverage/sotto-banco/cold-bar-200-blk/"
      },
      {
        "product": "Sotto Banco, cold-bar-200-inox",
        "link": "/settori/beverage/sotto-banco/cold-bar-200-inox/"
      },
      {
        "product": "Sotto Banco, cold-bar-300-blk",
        "link": "/settori/beverage/sotto-banco/cold-bar-300-blk/"
      },
      {
        "product": "Sotto Banco,cold-bar-300-inox",
        "link": "/settori/beverage/sotto-banco/cold-bar-300-inox/"
      },
      {
        "product": "Sotto Banco,bb-221-sd",
        "link": "/settori/beverage/sotto-banco/bb-221-sd/"
      },
      {
        "product": "Armadi Pensili,alaska-top-145",
        "link": "/settori/mini-market-super-market/armadi-pensili/alaska-top-145/"
      },
      {
        "product": "Sotto Banco,bb-322-sd",
        "link": "/settori/beverage/sotto-banco/bb-322-sd/"
      },
      {
        "product": "Sotto Banco,bb-423-sd",
        "link": "/settori/beverage/sotto-banco/bb-423-sd/"
      },
      {
        "product": "Armadi Pensili,alaska-top-210",
        "link": "/settori/mini-market-super-market/armadi-pensili/alaska-top-210/"
      },
      {
        "product": "Sotto Banco,bb-200-gd",
        "link": "/settori/beverage/sotto-banco/bb-200-gd/"
      },
      {
        "product": "Sotto Banco,bb-300-gd",
        "link": "/settori/beverage/sotto-banco/bb-300-gd/"
      },
      {
        "product": "Sotto Banco,bb-400-gd",
        "link": "/settori/beverage/sotto-banco/bb-400-gd/"
      },
      {
        "product": "Azotatrici,sommelier-4",
        "link": "/settori/wine/azotatrici/sommelier-4/"
      },
      {
        "product": "Azotatrici,sommelier-6",
        "link": "/settori/wine/azotatrici/sommelier-6/"
      },
      {
        "product": "Azotatrici,sommelier-8",
        "link": "/settori/wine/azotatrici/sommelier-8/"
      },
      {
        "product": "Sopra Banco,cl-20-fl-wht",
        "link": "/settori/beverage/sopra-banco/cl-20-fl-wht/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-67-st",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-67-st/"
      },
      {
        "product": "Armadi Acciaio,axt-710-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axt-710-tn/"
      },
      {
        "product": "Armadi,open-400",
        "link": "/settori/mini-market-super-market/armadi/open-400/"
      },
      {
        "product": "Sopra Banco,cl-20-fl-blk",
        "link": "/settori/beverage/sopra-banco/cl-20-fl-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-90-st",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-90-st/"
      },
      {
        "product": "Armadi Acciaio,axt-710-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axt-710-bt/"
      },
      {
        "product": "Armadi,mr-75-tn1",
        "link": "/settori/mini-market-super-market/armadi/mr-75-tn1/"
      },
      {
        "product": "Sopra Banco,cl-50-fl-wht",
        "link": "/settori/beverage/sopra-banco/cl-50-fl-wht/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-67-nfn",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-67-nfn/"
      },
      {
        "product": "Armadi Acciaio,axt-1520-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axt-1520-tn/"
      },
      {
        "product": "Armadi,mr-75-tn1-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-75-tn1-blk/"
      },
      {
        "product": "Sopra Banco,cl-50-fl-blk",
        "link": "/settori/beverage/sopra-banco/cl-50-fl-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-76-nfn",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-76-nfn/"
      },
      {
        "product": "Armadi Acciaio,axt-1520-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axt-1520-bt/"
      },
      {
        "product": "Armadi,mr-75-tn1-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-75-tn1-gry/"
      },
      {
        "product": "Sopra Banco,cl-70-fl-wht",
        "link": "/settori/beverage/sopra-banco/cl-70-fl-wht/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-90-nfn",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-90-nfn/"
      },
      {
        "product": "Armadi,mr-75-bt1",
        "link": "/settori/mini-market-super-market/armadi/mr-75-bt1/"
      },
      {
        "product": "Armadi Acciaio,axf-600-fish",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-600-fish/"
      },
      {
        "product": "Sopra Banco,cl-70-fl-blk",
        "link": "/settori/beverage/sopra-banco/cl-70-fl-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-132-st",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-132-st/"
      },
      {
        "product": "Armadi,mr-75-bt1-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-75-bt1-blk/"
      },
      {
        "product": "Armadi Acciaio,axf-350-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-350-tn/"
      },
      {
        "product": "Sopra Banco,cl-40-flc",
        "link": "/settori/beverage/sopra-banco/cl-40-flc/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-132-nfn-st",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-132-nfn-st/"
      },
      {
        "product": "Armadi,mr-75-bt1-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-75-bt1-gry/"
      },
      {
        "product": "Armadi Acciaio,axf-350-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-350-bt/"
      },
      {
        "product": "Sopra Banco,cl-40-flc-blk",
        "link": "/settori/beverage/sopra-banco/cl-40-flc-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-132-nfn",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-132-nfn/"
      },
      {
        "product": "Armadi,mr-126-tn2",
        "link": "/settori/mini-market-super-market/armadi/mr-126-tn2/"
      },
      {
        "product": "Armadi Acciaio,snack-400-tnf",
        "link": "/settori/arredo-cucine/armadi-acciaio/snack-400-tnf/"
      },
      {
        "product": "Sopra Banco,cl-52-flc",
        "link": "/settori/beverage/sopra-banco/cl-52-flc/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,psg-132-nfn-nfn",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/psg-132-nfn-nfn/"
      },
      {
        "product": "Armadi,mr-126-tn2-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-126-tn2-blk/"
      },
      {
        "product": "Armadi Acciaio,snack-400-btf",
        "link": "/settori/arredo-cucine/armadi-acciaio/snack-400-btf/"
      },
      {
        "product": "Sopra Banco,cl-52-flc-blk",
        "link": "/settori/beverage/sopra-banco/cl-52-flc-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,deluxe-4v",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/deluxe-4v/"
      },
      {
        "product": "Armadi,mr-126-tn2-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-126-tn2-gry/"
      },
      {
        "product": "Armadi Acciaio,axf-710-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-710-tn/"
      },
      {
        "product": "Sopra Banco,cl-80-flc",
        "link": "/settori/beverage/sopra-banco/cl-80-flc/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,deluxe-4v-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/deluxe-4v-black/"
      },
      {
        "product": "Armadi,mr-126-bt2",
        "link": "/settori/mini-market-super-market/armadi/mr-126-bt2/"
      },
      {
        "product": "Armadi Acciaio,axf-710-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-710-bt/"
      },
      {
        "product": "Sopra Banco,cl-80-flc-blk",
        "link": "/settori/beverage/sopra-banco/cl-80-flc-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfn-silver",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfn-silver/"
      },
      {
        "product": "Armadi,mr-126-bt2-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-126-bt2-blk/"
      },
      {
        "product": "Armadi Acciaio,axf-700-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-700-tn/"
      },
      {
        "product": "Sopra Banco,ckc-90",
        "link": "/settori/beverage/sopra-banco/ckc-90/"
      },
      {
        "product": "Armadi,mr-126-bt2-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-126-bt2-gry/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfn-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfn-white/"
      },
      {
        "product": "Armadi Acciaio,axf-700-tng",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-700-tng/"
      },
      {
        "product": "Sopra Banco,ckc-100",
        "link": "/settori/beverage/sopra-banco/ckc-100/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfn-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfn-black/"
      },
      {
        "product": "Armadi,mr-188-tn3",
        "link": "/settori/mini-market-super-market/armadi/mr-188-tn3/"
      },
      {
        "product": "Armadi Acciaio,axf-700-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-700-bt/"
      },
      {
        "product": "Sopra Banco,",
        "link": "/settori/beverage/sopra-banco//"
      },
      {
        "product": "Armadi,mr-188-tn3-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-188-tn3-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfp-silver",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfp-silver/"
      },
      {
        "product": "Armadi Acciaio,axf-700-btg",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-700-btg/"
      },
      {
        "product": "Armadi Acciaio,axf-800-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-800-tn/"
      },
      {
        "product": "Armadi,mr-188-tn3-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-188-tn3-gry/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfp-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfp-white/"
      },
      {
        "product": "Armadi,mr-188-bt3",
        "link": "/settori/mini-market-super-market/armadi/mr-188-bt3/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-65-nfp-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-65-nfp-black/"
      },
      {
        "product": "Armadi Acciaio,axf-800-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-800-bt/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfn-silver",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfn-silver/"
      },
      {
        "product": "Armadi,mr-188-bt3-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-188-bt3-blk/"
      },
      {
        "product": "Armadi Acciaio,axf-1520-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1520-tn/"
      },
      {
        "product": "Armadi,mr-188-bt3-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-188-bt3-gry/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfn-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfn-white/"
      },
      {
        "product": "Armadi Acciaio,axf-1520-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1520-bt/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfn-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfn-black/"
      },
      {
        "product": "Armadi,mr-250-tn4",
        "link": "/settori/mini-market-super-market/armadi/mr-250-tn4/"
      },
      {
        "product": "Armadi Acciaio,axf-1500-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1500-tn/"
      },
      {
        "product": "Armadi,mr-250-tn4-blk",
        "link": "/settori/mini-market-super-market/armadi/mr-250-tn4-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfp-silver",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfp-silver/"
      },
      {
        "product": "Armadi Acciaio,axf-1500-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1500-bt/"
      },
      {
        "product": "Armadi,mr-250-tn4-gry",
        "link": "/settori/mini-market-super-market/armadi/mr-250-tn4-gry/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfp-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfp-white/"
      },
      {
        "product": "Armadi Acciaio,axf-1600-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1600-tn/"
      },
      {
        "product": "Armadi,milano-630-wht",
        "link": "/settori/mini-market-super-market/armadi/milano-630-wht/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-85-nfp-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-85-nfp-black/"
      },
      {
        "product": "Armadi Acciaio,axf-1600-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/axf-1600-bt/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-132-nfn-silver",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-132-nfn-silver/"
      },
      {
        "product": "Armadi,milano-630-blk",
        "link": "/settori/mini-market-super-market/armadi/milano-630-blk/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-132-nfn-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-132-nfn-white/"
      },
      {
        "product": "Armadi Acciaio,gn-650-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/gn-650-tn/"
      },
      {
        "product": "Vetrine Verticali 4 Lati Vetro,victoria-132-nfn-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali-4-lati-vetro/victoria-132-nfn-black/"
      },
      {
        "product": "Armadi,milano-630-bt-blk",
        "link": "/settori/mini-market-super-market/armadi/milano-630-bt-blk/"
      },
      {
        "product": "Armadi Acciaio,gn-650-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/gn-650-bt/"
      },
      {
        "product": "Armadi,milano-1270-wht",
        "link": "/settori/mini-market-super-market/armadi/milano-1270-wht/"
      },
      {
        "product": "Armadi Acciaio,gn-1410-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/gn-1410-tn/"
      },
      {
        "product": "Armadi,milano-1270-blk",
        "link": "/settori/mini-market-super-market/armadi/milano-1270-blk/"
      },
      {
        "product": "Armadi Acciaio,gn-1410-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/gn-1410-bt/"
      },
      {
        "product": "Armadi Acciaio,ax-700-tng",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-700-tng/"
      },
      {
        "product": "Armadi,milano-1620-wht",
        "link": "/settori/mini-market-super-market/armadi/milano-1620-wht/"
      },
      {
        "product": "Armadi Acciaio,ax-700-tn-gn2-1",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-700-tn-gn2-1/"
      },
      {
        "product": "Armadi,milano-1620-blk",
        "link": "/settori/mini-market-super-market/armadi/milano-1620-blk/"
      },
      {
        "product": "Armadi Acciaio,ax-400-tnv-gn1-1",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-400-tnv-gn1-1/"
      },
      {
        "product": "Armadi,milano-1910-wht",
        "link": "/settori/mini-market-super-market/armadi/milano-1910-wht/"
      },
      {
        "product": "Armadi Acciaio,ax-1500-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-1500-tn/"
      },
      {
        "product": "Armadi,milano-1910-blk",
        "link": "/settori/mini-market-super-market/armadi/milano-1910-blk/"
      },
      {
        "product": "Armadi Acciaio,ax-700-bt-gn2-1",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-700-bt-gn2-1/"
      },
      {
        "product": "Armadi,vision-150",
        "link": "/settori/mini-market-super-market/armadi/vision-150/"
      },
      {
        "product": "Armadi Acciaio,ax-700-btg",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-700-btg/"
      },
      {
        "product": "Armadi,vision-200",
        "link": "/settori/mini-market-super-market/armadi/vision-200/"
      },
      {
        "product": "Armadi Acciaio,ax-400-btv-gn1-1",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-400-btv-gn1-1/"
      },
      {
        "product": "Armadi,vision-250",
        "link": "/settori/mini-market-super-market/armadi/vision-250/"
      },
      {
        "product": "Armadi Acciaio,ax-1500-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-1500-bt/"
      },
      {
        "product": "Armadi,mrr-75-bt1-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-75-bt1-blk/"
      },
      {
        "product": "Armadi Acciaio,ax-600-fish",
        "link": "/settori/arredo-cucine/armadi-acciaio/ax-600-fish/"
      },
      {
        "product": "Armadi,mrr-126-bt2-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-126-bt2-blk/"
      },
      {
        "product": "Armadi,mrr-188-bt3-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-188-bt3-blk/"
      },
      {
        "product": "Armadi,mrr-75-tn1-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-75-tn1-blk/"
      },
      {
        "product": "Armadi,mrr-126-tn2-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-126-tn2-blk/"
      },
      {
        "product": "Armadi,mrr-188-tn3-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-188-tn3-blk/"
      },
      {
        "product": "Armadi,mrr-250-tn4-blk",
        "link": "/settori/mini-market-super-market/armadi/mrr-250-tn4-blk/"
      },
      {
        "product": "Armadi Cucina ABS,gn-200-ps-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-200-ps-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-200-g-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-200-g-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-200-ps-bt",
        "link": "/settori/arredo-cucine/armadi-gn/gn-200-ps-bt/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-ps-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-ps-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-g-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-g-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-g-tn-blk",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-g-tn-blk/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-ps-bt",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-ps-bt/"
      },
      {
        "product": "Armadi Cucina ABS,gn-600-ps-bt",
        "link": "/settori/arredo-cucine/armadi-gn/gn-600-ps-bt/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-g-bt",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-g-bt/"
      },
      {
        "product": "Armadi Cucina ABS,gn-400-g-bt-blk",
        "link": "/settori/arredo-cucine/armadi-gn/gn-400-g-bt-blk/"
      },
      {
        "product": "Armadi Cucina ABS,gn-600-ps-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-600-ps-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-770-tn",
        "link": "/settori/arredo-cucine/armadi-gn/gn-770-tn/"
      },
      {
        "product": "Armadi Cucina ABS,gn-770-tng",
        "link": "/settori/arredo-cucine/armadi-gn/gn-770-tng/"
      },
      {
        "product": "Frigo pensili,coldwall-300",
        "link": "/settori/beverage/frigo-pensili/coldwall-300/"
      },
      {
        "product": "Frigo pensili,coldwall-300-inox",
        "link": "/settori/beverage/frigo-pensili/coldwall-300-inox/"
      },
      {
        "product": "Banchi refrigerati,sama-95-l",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-95-l/"
      },
      {
        "product": "Banchi refrigerati,sama-125-l",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-125-l/"
      },
      {
        "product": "Banchi refrigerati,sama-150-l",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-150-l/"
      },
      {
        "product": "Banchi refrigerati,sama-125-xl",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-125-xl/"
      },
      {
        "product": "Banchi refrigerati,sama-150-xl",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-150-xl/"
      },
      {
        "product": "Banchi refrigerati,sama-190-xl",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-190-xl/"
      },
      {
        "product": "Banchi refrigerati,sama-250-xl",
        "link": "/settori/mini-market-super-market/banchi-refrigerati/sama-250-xl/"
      },
      {
        "product": "Saladettes,saladette-sf-901",
        "link": "/settori/arredo-cucine/saladettes/saladette-sf-901/"
      },
      {
        "product": "Saladettes,saladette-sf-903-top",
        "link": "/settori/arredo-cucine/saladettes/saladette-sf-903-top/"
      },
      {
        "product": "Saladettes,saladette-sf-903-pz",
        "link": "/settori/arredo-cucine/saladettes/saladette-sf-903-pz/"
      },
      {
        "product": "Saladettes,saladette-s-901-top",
        "link": "/settori/arredo-cucine/saladettes/saladette-s-901-top/"
      },
      {
        "product": "Saladettes,saladette-s-903-pz",
        "link": "/settori/arredo-cucine/saladettes/saladette-s-903-pz/"
      },
      {
        "product": "Saladettes,saladette-s-903-top",
        "link": "/settori/arredo-cucine/saladettes/saladette-s-903-top/"
      },
      {
        "product": "Congelatori Verticali,gn-400g-bt",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/gn-400g-bt/"
      },
      {
        "product": "Congelatori Verticali,gn-400g-bt-blk",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/gn-400g-bt-blk/"
      },
      {
        "product": "Pozzetti,cl-300-psk",
        "link": "/settori/beverage/pozzetti/cl-300-psk/"
      },
      {
        "product": "Pozzetti,cl-400-psk",
        "link": "/settori/beverage/pozzetti/cl-400-psk/"
      },
      {
        "product": "Pozzetti,bl-65-f",
        "link": "/settori/beverage/pozzetti/bl-65-f/"
      },
      {
        "product": "Pozzetti,btg-90",
        "link": "/settori/beverage/pozzetti/btg-90/"
      },
      {
        "product": "Pozzetti,btg-125",
        "link": "/settori/beverage/pozzetti/btg-125/"
      },
      {
        "product": "Pozzetti,btg-163",
        "link": "/settori/beverage/pozzetti/btg-163/"
      },
      {
        "product": "Congelatori Verticali,slim-freezer-225-t-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/slim-freezer-225-t-white/"
      },
      {
        "product": "Congelatori Verticali,slim-freezer-225-t-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/slim-freezer-225-t-black/"
      },
      {
        "product": "Congelatori Verticali,fr-372-vgc-st",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-372-vgc-st/"
      },
      {
        "product": "Congelatori Verticali,fr-372-v-st-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-372-v-st-white/"
      },
      {
        "product": "Congelatori Verticali,fr-372-v-st-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-372-v-st-black/"
      },
      {
        "product": "Congelatori Verticali,fr-410-st-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-410-st-black/"
      },
      {
        "product": "Congelatori Verticali,fr-410-st-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-410-st-white/"
      },
      {
        "product": "Congelatori Verticali,fr-570-vgc-nf",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-570-vgc-nf/"
      },
      {
        "product": "Congelatori Verticali,fr-1240-vgc-nf",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-1240-vgc-nf/"
      },
      {
        "product": "Congelatori Verticali,fr-55-vg-fl-white",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-55-vg-fl-white/"
      },
      {
        "product": "Congelatori Verticali,fr-55-vg-fl-black",
        "link": "/settori/pasticceria-gelateria/congelatori-verticali/fr-55-vg-fl-black/"
      },
      {
        "product": "Vetrine Gelato,ice-7",
        "link": "/settori/pasticceria-gelateria/vetrine-gelato/ice-7/"
      },
      {
        "product": "Vetrine Gelato,ice-8",
        "link": "/settori/pasticceria-gelateria/vetrine-gelato/ice-8/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2100-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2100-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,frc-250-pff",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frc-250-pff/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2200-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2200-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,frc-300-pff",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frc-300-pff/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2100-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2100-btf/"
      },
      {
        "product": "Congelatori a Pozzo,frc-395-pff",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frc-395-pff/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2200-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2200-btf/"
      },
      {
        "product": "Congelatori a Pozzo,frc-500-pff",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frc-500-pff/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3100-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3100-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,frc-600-pff",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frc-600-pff/"
      },
      {
        "product": "Congelatori a Pozzo,fr-200-pff-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-200-pff-k/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3200-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3200-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-300-pff-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-300-pff-k/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3100-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3100-btf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-400-pff-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-400-pff-k/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3200-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3200-btf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-500-pff-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-500-pff-k/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4100-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4100-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-152-pac-paf",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-152-pac-paf/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4200-tnf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4200-tnf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-200-pac-paf",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-200-pac-paf/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4100-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4100-btf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-300-pac-paf",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-300-pac-paf/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4200-btf",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4200-btf/"
      },
      {
        "product": "Congelatori a Pozzo,fr-400-pac-paf",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-400-pac-paf/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-2100-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-500-pac-paf",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-500-pac-paf/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-2200-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-200-ps-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-200-ps-k/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-2100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-2100-bt/"
      },
      {
        "product": "Congelatori a Pozzo,fr-300-ps-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-300-ps-k/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-2200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-2200-bt/"
      },
      {
        "product": "Congelatori a Pozzo,fr-400-ps-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-400-ps-k/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-2100-btn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-2100-btn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-500-ps-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-500-ps-k/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3100-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-600-ps-k",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-600-ps-k/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3100-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3100-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3200-tn/"
      },
      {
        "product": "Congelatori a Pozzo,frp-300-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frp-300-pssl/"
      },
      {
        "product": "Congelatori a Pozzo,frp-400-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frp-400-pssl/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3100-btn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3100-btn/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3100-bt/"
      },
      {
        "product": "Congelatori a Pozzo,frp-500-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/frp-500-pssl/"
      },
      {
        "product": "Congelatori a Pozzo,fr-300-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-300-pssl/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3200-bt/"
      },
      {
        "product": "Congelatori a Pozzo,fr-400-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-400-pssl/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-3140-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-3140-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-500-pssl",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-500-pssl/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4100-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-300-flp",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-300-flp/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4200-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-400-flp",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-400-flp/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4200-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4200-tng/"
      },
      {
        "product": "Congelatori a Pozzo,fr-500-flp",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-500-flp/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4100-bt/"
      },
      {
        "product": "Congelatori a Pozzo,fr-158-slc",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-158-slc/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4200-bt/"
      },
      {
        "product": "Congelatori a Pozzo,fr-368-slc",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-368-slc/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4110-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4110-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-468-slc",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-468-slc/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4130-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4130-tn/"
      },
      {
        "product": "Congelatori a Pozzo,fr-568-slc",
        "link": "/settori/pasticceria-gelateria/congelatori-a-pozzo/fr-568-slc/"
      },
      {
        "product": "Tavoli Refrigerati,cxf-4140-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cxf-4140-tn/"
      },
      {
        "product": "Tavoli Refrigerati,clv-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/clv-2200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,clv-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/clv-3200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,clv-4200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/clv-4200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,pxf-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/pxf-2100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,pxf-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/pxf-2200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,pxf-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/pxf-3100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,pxf-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/pxf-3200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-2100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-2200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-3100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-3200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-4100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-4100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,gn-4200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/gn-4200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,ax-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/ax-2100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-2100-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-2100-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-2200-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-2200-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-2200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3100-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3100-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3110-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3110-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3130-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3130-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3140-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3140-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3200-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3200-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4110-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4110-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4100-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4100-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4140-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4140-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4120-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4120-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4130-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4130-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4150-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4150-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4200-tng",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4200-tng/"
      },
      {
        "product": "Tavoli Refrigerati,cax-2100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-2100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,cax-2200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-2200-bt/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,cax-3200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-3200-bt/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,cax-4200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/cax-4200-bt/"
      },
      {
        "product": "Tavoli Refrigerati,psx-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/psx-2100-tn/"
      },
      {
        "product": "Mini Bar,mb-30-eco-white",
        "link": "/settori/beverage/mini-bar/mb-30-eco-white/"
      },
      {
        "product": "Tavoli Refrigerati,psx-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/psx-2200-tn/"
      },
      {
        "product": "Mini Bar,mb-30-eco-black",
        "link": "/settori/beverage/mini-bar/mb-30-eco-black/"
      },
      {
        "product": "Tavoli Refrigerati,psx-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/psx-3100-tn/"
      },
      {
        "product": "Mini Bar,mb-40-eco-white",
        "link": "/settori/beverage/mini-bar/mb-40-eco-white/"
      },
      {
        "product": "Tavoli Refrigerati,psx-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/psx-3200-tn/"
      },
      {
        "product": "Mini Bar,mb-40-eco-black",
        "link": "/settori/beverage/mini-bar/mb-40-eco-black/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4100-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4100-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4200-tn",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4200-tn/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,snack-2200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-2200-bt/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,snack-3200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-3200-bt/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4100-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4100-bt/"
      },
      {
        "product": "Tavoli Refrigerati,snack-4200-bt",
        "link": "/settori/arredo-cucine/tavoli-refrigerati/snack-4200-bt/"
      },
      {
        "product": "Banchi Pizza,pzf-01-c",
        "link": "/settori/arredo-cucine/banchi-pizza/pzf-01-c/"
      },
      {
        "product": "Armadi Acciaio,cream-995-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/cream-995-bt/"
      },
      {
        "product": "Banchi Pizza,pzf-02",
        "link": "/settori/arredo-cucine/banchi-pizza/pzf-02/"
      },
      {
        "product": "Armadi Acciaio,pxf-990-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/pxf-990-tn/"
      },
      {
        "product": "Armadi Acciaio,cream-990-bt",
        "link": "/settori/arredo-cucine/armadi-acciaio/cream-990-bt/"
      },
      {
        "product": "Banchi Pizza,pzf-02-c",
        "link": "/settori/arredo-cucine/banchi-pizza/pzf-02-c/"
      },
      {
        "product": "Armadi Acciaio,psx-990-tn",
        "link": "/settori/arredo-cucine/armadi-acciaio/psx-990-tn/"
      },
      {
        "product": "Banchi Pizza,pzf-03",
        "link": "/settori/arredo-cucine/banchi-pizza/pzf-03/"
      },
      {
        "product": "Banchi Pizza,pz-1610",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-1610/"
      },
      {
        "product": "Banchi Pizza,pz-2600",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-2600/"
      },
      {
        "product": "Banchi Pizza,pz-2610",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-2610/"
      },
      {
        "product": "Banchi Pizza,pz-3600",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-3600/"
      },
      {
        "product": "Banchi Pizza,cassettiera",
        "link": "/settori/arredo-cucine/banchi-pizza/cassettiera/"
      },
      {
        "product": "Banchi Pizza,pz-01-c",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-01-c/"
      },
      {
        "product": "Banchi Pizza,pz-02",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-02/"
      },
      {
        "product": "Banchi Pizza,pz-02-c",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-02-c/"
      },
      {
        "product": "Banchi Pizza,pz-03",
        "link": "/settori/arredo-cucine/banchi-pizza/pz-03/"
      },
      {
        "product": "Vetrine Refrigerate,fishow-70",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/fishow-70/"
      },
      {
        "product": "Granitori,patty-30",
        "link": "/settori/pasticceria-gelateria/granitori/patty-30/"
      },
      {
        "product": "Vetrine Refrigerate,fishow-100",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/fishow-100/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1202",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1202/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1402",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1402/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1502",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1502/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1802",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1802/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-2002",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-2002/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1000-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1000-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1000-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1000-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1200-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1200-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1200-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1200-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1400-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1400-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1400-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1400-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1500-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1500-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1500-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1500-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1600-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1600-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1600-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1600-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1800-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1800-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-1800-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-1800-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-2000-330-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-2000-330-fg/"
      },
      {
        "product": "Vetrine Refrigerate,vrx-2000-380-fg",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/vrx-2000-380-fg/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1200",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1200/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1400",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1400/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1500",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1500/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-1800",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-1800/"
      },
      {
        "product": "Vetrine Refrigerate,rwx-2000",
        "link": "/settori/arredo-cucine/vetrine-refrigerate/rwx-2000/"
      },
      {
        "product": "Sushi,sushi-120",
        "link": "/settori/arredo-cucine/sushi/sushi-120/"
      },
      {
        "product": "Sushi,sushi-135",
        "link": "/settori/arredo-cucine/sushi/sushi-135/"
      },
      {
        "product": "Sushi,sushi-150",
        "link": "/settori/arredo-cucine/sushi/sushi-150/"
      },
      {
        "product": "Sushi,sushi-180",
        "link": "/settori/arredo-cucine/sushi/sushi-180/"
      },
      {
        "product": "Sushi,sh-150",
        "link": "/settori/arredo-cucine/sushi/sh-150/"
      },
      {
        "product": "Sushi,sh-180",
        "link": "/settori/arredo-cucine/sushi/sh-180/"
      }
    ]

    console.log("Items: ", list.map(x => x["link"]));
  }

}





function DeepCopy(item: any): any {
  return JSON.parse(JSON.stringify(item));
}