/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: Mypit.Compliance.ts;
 * Path: /src/environments/Modules/Brandizzazioni.ts;
 * Description: ;
 * Author: Della Porta Giovanni Battista;
 * Created at: 10/03/2024, 13:30;
 *
 *
 */

import { OggettoConfig } from "src/app/shared/_services/dynamic-crud.service";
import { Scaduta, isDateNearExpiry } from "../Utility/DateUtility";
import { ToastrService } from "ngx-toastr";
import { ApiPathsService } from "src/app/api-paths.service";
import { Fasi } from "../Tasks/Fasi";
import { Field } from "src/app/Stampe/Models/Field";
import { Permission } from "src/app/Login/_guards/Permission";

function Config() {
  var OBJ: OggettoConfig = {
    HasAddList: {},//da usare come hideif
    Capacita: ['gestione-lavorazioni'],
    Id: "brandizzazioni",
    listTitle: "Lavorazioni",
    //EditView: 'Page',
    RestRoute: '/progetti/lavorazioni',
    ListResponseHasData: true,
    GetResponseHasData: true,
    HasGet: false,
    ListDefaultFilter: {
      stato: 'LL', statoObject: {  id: "LL",  label: "Da Lavorare" } },
    OnSaveMap: (x) => {
      return {
        id:x?.id,
        voci: [
          x
        ]
      }
    },
    MenuItems: [
      {
        path: "/shared/dynamiclist/brandizzazioni",
        title: "Lavorazioni",
        icon: "fas fa-wrench",
        class: "",
        capacita: ['menu-lavorazioni'],
      },
    ],
    campi: [
      { nome: 'Data', oggetti: ['progettoObject'], type: 'object', field: ['data_creazione'], inputType: 'date' },
      { nome: 'Prodotto', oggetti: ['voce'], inputType: 'text' },
      { nome: 'Ordine', oggetti: ['progettoObject'], type: 'object', inputType: 'text', field: ['codice'] },
      { nome: 'Lavorato', oggetti: ['brandizzato'], inputType: 'checkbox', modificabile: true },
      {
        nome: 'In evasione', oggetti: ['brandizzato'], inputType: 'checkbox', modificabile: true, hideif: {
          func: (x, permission: Permission) => { 
            return permission.isPermitted('gestione-progetti');
      } } }
    ],
  
    AzioniLista: [

    ],
    Azioni: [

    ],
    Headers: [
      { nome: 'Data', oggetti: ['progettoObject'], type: 'object', field: ['data_creazione'], inputType: 'date' },
      { nome: 'Prodotto', oggetti: ['voce'], inputType: 'text' },
      { nome: 'Ordine', oggetti: ['progettoObject'], type: 'object', inputType: 'text', field: ['codice'] },
      {
        nome: 'Lavorato',
        modificabile: false,
        oggetti: ['brandizzato'], type: 'html', printable: false, func: (oggetto, parent) => {
          var Aperta = `
                        <a href="javascript:void(0)" tooltip="brandizzato" class=" status-icon link font-16 text-success font-weight-bold" >
                            <span class="tooltiptext"> Lavorato </span>
                        </a>
                    `
          var chiusa = `
                        <a href="javascript:void(0)" class=" status-icon link font-16 text-warning font-weight-bold" >
                            <span class="tooltiptext"> Da Lavorare </span>
                        </a>
                    `
          if (!oggetto.brandizzato)
            return [chiusa];
            if (oggetto.brandizzato)
            return [Aperta];
          else return [];

          // return [{ component: Alert, params: { tooltip: 'In scadenza', aclass: null, iclass: null, color: null } }];
        }
      },
       {
        nome: 'In Evasione',
        modificabile: false,
        oggetti: ['evasione'], type: 'html', printable: false, func: (oggetto, parent) => {
          var Aperta = `
                        <a href="javascript:void(0)" tooltip="brandizzato" class=" status-icon link font-16 text-success font-weight-bold" >
                            <span class="tooltiptext"> InEvasione </span>
                        </a>
                    `
          var chiusa = `
                        <a href="javascript:void(0)" class=" status-icon link font-16 text-warning font-weight-bold" >
                            <span class="tooltiptext"> Da Evadere </span>
                        </a>
                    `
          if (!oggetto.evasione)
            return [chiusa];
          if (oggetto.evasione)
            return [Aperta];
          else return [];

          // return [{ component: Alert, params: { tooltip: 'In scadenza', aclass: null, iclass: null, color: null } }];
        }
      }
    ],
    Filters: [

      {
        nome: 'menu.clienti',
        oggetti: ['cliente'],
        class: 'col-lg-6',
        modificabile: true,
        visibleifProhibited: true,
        OnChange: 'refresh',

      },
      {
        nome: 'Stato',
        source: "/progetti/lavorazioni/stati",
        oggetti: ['stato'],
        internalOutput: 'statoObject',
        OutputField: 'stato',
        OnChange: 'refresh',
        modificabile: true,
        inputType: 'singleDropdown',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'label',
        },
        class: 'col-lg-6',
      },

    ]
  }
  return OBJ;
};




export var Brandizzazioni = () => {

  return {
    Cruds: [Config()],
    Menu: [...Config().MenuItems],
    Modules: {
      "Brandizzazioni": true
    },

  };
}
